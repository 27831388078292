import React from "react"
import Layout from "../components/Layout"
import "../css/Blog/tech_blogpage.css"
import Particle9 from "../components/Particle9"
import SEO from "../components/SEO"

const tech_blogpage = () => {
  return (
    <Layout>
      <SEO
        title="TechPro"
        description="This is Tech Programming blog page, by Arpit Somani."
      />
      <Particle9></Particle9>
      <h6>s</h6>
      <section className="tech-blog-post-wrapper noSelect">
        <h1>TechPro</h1>
        <h3>
          You will find cool and interesting stuff's as the name suggest its all
          about the "Tech Programming"
        </h3>

        <div className="next-blog noSelect">
          <h4></h4>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://arpitsomani.blogspot.com/2020/08/the-straight-path-to-web-development.html"
        >
          <div className="blog-box noSelect">
            <h4>How to Create AWS EC2 Instance Cloud?</h4>
            <h5>25 Jul 2021</h5>
            <button type="button" className="blog-btn noSelect">
              View Post
            </button>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://arpitsomani.blogspot.com/2020/08/the-straight-path-to-web-development.html"
        >
          <div className="blog-box noSelect">
            <h4>Python Modules-Packages-Libraries</h4>
            <h5>17 Jul 2021</h5>
            <button type="button" className="blog-btn noSelect">
              View Post
            </button>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://arpitsomani.blogspot.com/2021/07/screen-pet-by-python.html"
        >
          <div className="blog-box noSelect">
            <h4>Screen Pet By Python</h4>
            <h5>07 Jul 2021</h5>
            <button type="button" className="blog-btn noSelect">
              View Post
            </button>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://arpitsomani.blogspot.com/2020/10/github-contribution-special.html"
        >
          <div className="blog-box noSelect">
            <h4>Github - Contribution Special</h4>
            <h5>14 oct 2020</h5>
            <button type="button" className="blog-btn noSelect">
              View Post
            </button>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://arpitsomani.blogspot.com/2020/09/the-evergreen-python.html"
        >
          <div className="blog-box noSelect">
            <h4>The Evergreen: Python</h4>
            <h5>13 Sep 2020</h5>
            <button type="button" className="blog-btn noSelect">
              View Post
            </button>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://arpitsomani.blogspot.com/2020/09/full-stack-web-development-mern.html"
        >
          <div className="blog-box noSelect">
            <h4>Full Stack Development: MERN</h4>
            <h5>1 Sep 2020</h5>
            <button type="button" className="blog-btn noSelect">
              View Post
            </button>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://arpitsomani.blogspot.com/2020/08/the-straight-path-to-web-development.html"
        >
          <div className="blog-box noSelect">
            <h4>The Straight Path to Web Development!</h4>
            <h5>21 Aug 2020</h5>
            <button type="button" className="blog-btn noSelect">
              View Post
            </button>
          </div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://arpitsomani.blogspot.com/2020/07/git-and-github.html"
        >
          <div className="blog-box noSelect">
            <h4>Git and Github World !</h4>
            <h5>29 Jul 2020</h5>
            <button type="button" className="blog-btn noSelect">
              View Post
            </button>
          </div>
        </a>
      </section>
    </Layout>
  )
}

export default tech_blogpage
